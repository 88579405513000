/* For larger screens (desktop/tablet) */
.sidebar {
  display: block;
}

/* For mobile screens */
@media screen and (max-width: 1024px) {
  .sidebar {
    display: none;
  }

  .PostCard {
    padding: 8px;
    margin: 8px;
    /* Adjust margin for better mobile view */
  }
}

.sidebar.active {
  display: block;
}

.video-frame-size {
  width: 100%;
  height: 100%;
  max-height: 600px;
  align-items: center;
  padding: 1px;
  margin: 5px;
}

.icon-container svg {
  margin-bottom: 4px;
  /* Adjust as needed for spacing between icon and count */
}

.popup-container {
  background-color: inherit;
  overflow-y: scroll;
  max-height: 100px;
}

.popup-header {
  background-color: inherit;
  color: aqua;
}

.detailVideo {
  width: 600px;
  height: 300px;
}

.description {
  width: 100%;
  height: 100%;
  max-height: 600px;
  align-items: center;
  padding: 8px;
}

.seeker-info {
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 8px;
}

.detail-buttons {
  padding: 5px;
  background-color: antiquewhite;
  justify-content: center;
}