
.page { 
  position: relative;
  margin-top: 5em;
  max-width: 1070px;
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin-left: 2em;
}


.field-width {
  width: 450px;
}

.create-form-card{
  align-items: center;
  justify-content: center;
  padding: 15px;
  margin-left: 30px;
  margin-right: 10px;
}

.post-description{
  width: 100%;
}