.comments{
  margin-top: 1em;
  width: 100%;
  padding: 5px;
  background-color: aliceblue;
  justify-content: center;
}

.comment-form{
  width: 100%;
  padding: 5px;
  background-color: aliceblue;
  justify-content: center;
}

.comment-form input{
  width: 90%;
  padding: 5px;
  border: 1px solid white ;
  border-radius: 5px;
  background-color: rgb(220, 239, 255);
  justify-content: center;
}

.comment-form button{
  width: 10%;
  padding: 5px;
  background-color: rgb(22, 132, 228);
  justify-content: center;
  font-weight: bold;
  color: white;
}


.comment-object{
  width: 100%;
  padding: 5px;
  border: 2px solid black;
  background-color: aliceblue;
}